import { t } from "i18next";
import { memo, useContext, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { isEqual } from "lodash";
import { CollabDocQuestionInput, CommentsList, NewCommentForm } from ".";
import multipleChoiceQuestionHelper from "../../helpers/multipleChoiceQuestionHelper";
import { AnswerSetApprovalStatus } from "../../types/collab-docs";
import CollabDocFlaggedChangeDto from "../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import {
  FormQuestion,
  QuestionAnswer,
  QuestionAnswerType,
  QuestionAnswerValue,
  QuestionTasks,
  ValidationResult,
} from "../../types/forms";
import { Badge, Label, ModalPopup, SafeRenderHtml } from "../common";
import CommentIndicator from "./CommentIndicator";
import { newCommentFieldElementId } from "./NewCommentForm";
import CollabDocFlaggedChange from "./CollabDocFlaggedChange";
import UserContext from "../../state/UserContext";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../types/dtos/generic";
import {
  commentsHelper,
  questionTextHelper,
  userDetailsHelper,
} from "../../helpers";
import QuestionReadonlyBadge from "../forms/QuestionReadonlyBadge";
import BehaviourMoreInfo from "../forms/advanced/behaviours/BehaviourMoreInfo";
import {
  EnforcedCommentType,
  FormComplexities,
  NewCommentDto,
  SavedCommentDto,
} from "../../types/dtos/forms";
import ActorRestrictionPrintLabel from "./ActorRestrictionPrintLabel";
import MoreInfo from "../forms/advanced/MoreInfo";
import MultipleChoiceOptionNumericId from "../../types/forms/MultipleChoiceOptions";
import BehaviourSubtext from "../forms/advanced/behaviours/BehaviourSubtext";
import { faCommentPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvancedTaskDto from "../../types/dtos/forms/AdvancedTaskDto";

interface CollabDocQuestionProps {
  answerSetUniqueId: string;
  answerSetDateCreated: Date;
  isReadOnly: boolean;
  isDocLocked: boolean;
  formApprovalStatus: AnswerSetApprovalStatus;
  comments: Array<SavedCommentDto>;
  participants: Array<UserBasicDetailsDto>;
  question: FormQuestion;
  currentAnswer: QuestionAnswer | null;
  tasks: QuestionTasks | null;
  formActiveQuestionId: string | null;
  showValidationErrors: boolean;
  subjectUser: BaseUserDetailsDto;
  showChangeDetails: boolean;
  formColor: string;
  formComplexities: FormComplexities;
  /** The date/time the form data was loaded (must be UTC) */
  dateFormLoaded: Date;
  /** Recent form answer change to highlight to the user, if there is one.
   * There should be one for normal questions like text, multi-choice, but can be multiple
   * for things like behaviours as it's one QuestionId but multiple fields per behaviour/attribute
   */
  flaggedChanges: CollabDocFlaggedChangeDto[];
  showPlanningResponses: boolean;
  /** Whether or not we're in Dual Prep prep mode */
  isInPrepMode: boolean;
  isPrinting: boolean;
  /** Handle the value changing for this question (in the state in parent components) */
  onValueChange(
    questionId: string,
    newValue: QuestionAnswerValue,
    answerType: QuestionAnswerType
  ): QuestionAnswer | null;
  /** When wanting to save a question's answer via the API */
  onValueSave(
    answer: QuestionAnswer,
    nonStateValue: QuestionAnswer | null,
    onSuccess: () => void,
    onError: () => void
  ): void;

  /** When attempting to save again what is in the answer state (e.g. on save failure) */
  onRetryValueSave(
    questionId: string,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When attempting to save again what is in the state for tasks (e.g. on save failure) */
  onRetryTasksSave(
    questionId: string,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When attempting to save again what is in the state for enforced comments (e.g. on save failure) */
  onRetryEnforcedCommentSave(
    questionId: string,
    behaviourId: number | null,
    goalId: number | null,
    nonStateValue: SavedCommentDto | null,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** The active question determines the state of the comments sidebar */
  onQuestionFocus(questionId: string): void;
  /** A method to call to mark the comments as seen */
  onCommentsSeen(questionId: string): void;
  /** A method to call to insert a new comment */
  onCommentAdd(
    newComment: NewCommentDto,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
  /** A method to call to delete a comment */
  onCommentDelete(
    commentId: string,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
  /** A method to call to update the state value (single) enforced comment for this question (if one exists, otherwise creates a new comment) */
  onEnforcedCommentEdit(
    questionId: string,
    commentText: string,
    commentFor: EnforcedCommentType,
    objectId: number,
    clientFormId: number
  ): SavedCommentDto;
  /** A method to call to update the enforced comment in the database */
  onEnforcedCommentSave(
    questionId: string,
    behaviourId: number | null,
    goalId: number | null,
    nonStateValue: SavedCommentDto | null,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionTasks(
    questionTasks: QuestionTasks,
    onSuccess: () => void,
    onError: () => void
  ): void;
  onChangeAdvancedTasks(
    questionId: string,
    tasks?: AdvancedTaskDto[],
    cancelledTasks?: AdvancedTaskDto[]
  ): void;
}
/** We need to use React.memo as questions are rendered as a list,
 * and with lists, when the parent state updates, every child component re-renders
 * But in complex forms, that can lead to a slow UI where lots of components re-render unncessarily.
 * This is equivalent to the old "shouldComponentUpdate" effectively.
 * https://staleclosures.dev/preventing-list-rerenders/
 */
const collabDocComponentPropsAreEqual = (
  prevProps: Readonly<CollabDocQuestionProps>,
  nextProps: Readonly<CollabDocQuestionProps>
): boolean => {
  if (prevProps.formActiveQuestionId !== nextProps.formActiveQuestionId)
    return false;
  if (prevProps.showValidationErrors !== nextProps.showValidationErrors)
    return false;
  if (!isEqual(prevProps.currentAnswer, nextProps.currentAnswer)) return false;
  if (!isEqual(prevProps.tasks, nextProps.tasks)) return false;
  if (!isEqual(prevProps.comments, nextProps.comments)) return false;
  if (!isEqual(prevProps.isPrinting, nextProps.isPrinting)) return false;
  if (!isEqual(prevProps.flaggedChanges, nextProps.flaggedChanges))
    return false;
  if (!isEqual(prevProps.formComplexities, nextProps.formComplexities))
    return false;

  if (!isEqual(prevProps.onValueChange, nextProps.onValueChange)) return false;
  /*if (!isEqual(prevProps.onValueSave, nextProps.onValueSave)) return false;
  if (!isEqual(prevProps.onSaveRetry, nextProps.onSaveRetry)) return false;*/

  return true;
};
/** This is the component which renders the correct question type based on the details passed in.
 * State is managed at the CollabDoc level.
 */
const CollabDocQuestion = memo(
  ({
    answerSetUniqueId,
    answerSetDateCreated,
    question,
    currentAnswer,
    comments,
    tasks,
    participants,
    formActiveQuestionId,
    isReadOnly,
    isDocLocked,
    formApprovalStatus,
    showValidationErrors,
    flaggedChanges,
    showPlanningResponses,
    dateFormLoaded,
    subjectUser,
    formColor,
    formComplexities,
    isInPrepMode,
    isPrinting,
    onValueChange,
    onValueSave,
    onQuestionFocus,
    onCommentsSeen,
    onCommentAdd,
    onCommentDelete,
    onEnforcedCommentEdit,
    onEnforcedCommentSave,
    onChangeQuestionTasks,
    onRetryValueSave,
    onRetryTasksSave,
    onRetryEnforcedCommentSave,
    onChangeAdvancedTasks,
  }: CollabDocQuestionProps) => {
    // Context
    const userContext = useContext(UserContext);
    // Refs
    const commentFormInputRef = useRef<HTMLTextAreaElement>(null);
    // State
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [replyToCommentId, setReplyToCommentId] = useState<string | null>(
      null
    );
    const [validationResult, setValidationResult] =
      useState<ValidationResult | null>(null);
    const [showAddCommentSuggestion, setShowAddCommentSuggestion] =
      useState<boolean>(false);
    // Calculated values and private variables
    const standardComments = comments.filter(
      (x) => x.commentType === "STANDARD"
    );
    const unseenCommentCount = commentsHelper.getUnseenCommentCount(
      standardComments,
      userContext.user.id
    );
    let multipleChoiceQuestionOptions: MultipleChoiceOptionNumericId[] = [];
    // Update the multiple choice options when the question changes
    useEffect(() => {
      multipleChoiceQuestionOptions =
        multipleChoiceQuestionHelper.initialiseMultipleChoiceOptions(
          question,
          currentAnswer ? currentAnswer.answer : null,
          true
        );
    }, [question]); // eslint-disable-line react-hooks/exhaustive-deps

    // Update the validation errors when the answer changes
    useEffect(() => {
      const answerToValidate =
        currentAnswer !== null ? currentAnswer.answer : null;
      const tasksToValidate = tasks !== null ? tasks.tasks : null;

      setValidationResult(
        question.validate(
          answerToValidate,
          tasksToValidate,
          question.taskManagementConfig ?? null,
          comments,
          userContext.user.id,
          subjectUser.userId,
          "COLLAB-DOC"
        )
      );
    }, [question, currentAnswer, tasks, comments]);

    // If the answer changes, or comments are added, calculate whether or not to display the
    // "Suggest you add a comment for context" line
    useEffect(() => {
      if (!currentAnswer) {
        setShowAddCommentSuggestion(false);
      } else {
        const hasBeenAnsweredInThisSession =
          currentAnswer &&
          currentAnswer.userId === userContext.user.id &&
          currentAnswer.timestamp > dateFormLoaded;
        const hasBeenCommentedInThisSession =
          commentsForQuestion.filter(
            (x) =>
              x.authorId === userContext.user.id && x.timestamp > dateFormLoaded
          ).length > 0;

        const userIsSubjectUser = userContext.user.id === subjectUser.userId;
        const latestStatusWasFromOtherUser =
          (userIsSubjectUser &&
            (formApprovalStatus === "APPROVED-BY-MANAGER" ||
              formApprovalStatus === "RETURNED-BY-MANAGER")) ||
          (!userIsSubjectUser &&
            (formApprovalStatus === "APPROVED-BY-EMPLOYEE" ||
              formApprovalStatus === "RETURNED-BY-EMPLOYEE"));

        const doShowSuggestion =
          latestStatusWasFromOtherUser &&
          hasBeenAnsweredInThisSession &&
          !hasBeenCommentedInThisSession;
        setShowAddCommentSuggestion(doShowSuggestion);
      }
    }, [question, currentAnswer, comments]);

    // Events
    const onDisplayComments = (mode: "MODAL" | "SIDEBAR") => {
      switch (mode) {
        case "MODAL":
          setModalIsOpen(true);
          // Mark the comments as seen
          if (unseenCommentCount > 0) {
            onCommentsSeen(question.questionId);
          }
          break;
        case "SIDEBAR":
          onQuestionFocus(question.questionId);
          // If the user has clicked to show comments for a question
          // that has no (standard) comments, we can assume they want to add a comment
          if (standardComments.length === 0) {
            // Ideally we'd use the ref for this, but it was difficult (always null at this point in the code)
            // so we've resorted to getting the input by id
            const newCommentTextArea = document.getElementById(
              newCommentFieldElementId
            );
            if (newCommentTextArea) {
              newCommentTextArea.focus();
            }
          }
          // Comments are marked as seen when the sidebar loads
          break;
      }
    };
    /** Get the selected option id(s) and update the state on the parent */
    const onMultipleChoiceQuestionValueChange = (
      updatedMultiChoiceOptions: MultipleChoiceOptionNumericId[]
    ) => {
      const returnValue =
        multipleChoiceQuestionHelper.getAnswersFromMultiChoiceOptions(
          updatedMultiChoiceOptions,
          question
        );
      onValueChange(question.questionId, returnValue, "MULTICHOICE");
    };

    const handleAnswerChange = (
      newValue: QuestionAnswerValue,
      answerType: QuestionAnswerType
    ): QuestionAnswer | null => {
      return onValueChange(question.questionId, newValue, answerType);
    };

    const handleEnforcedCommentChange = (
      newValue: string,
      commentFor: EnforcedCommentType,
      objectId: number,
      clientFormId: number
    ): SavedCommentDto => {
      const output = onEnforcedCommentEdit(
        question.questionId,
        newValue,
        commentFor,
        objectId,
        clientFormId
      );

      return output;
    };

    const handleEnforcedCommentSave = (
      behaviourId: number | null,
      goalId: number | null,
      nonStateValue: SavedCommentDto | null,
      onSuccess: () => void,
      onError: () => void
    ) => {
      onEnforcedCommentSave(
        question.questionId,
        behaviourId,
        goalId,
        nonStateValue,
        onSuccess,
        onError
      );
    };

    // Rendered elements
    const inputId = `question_input_${question.questionId}`;
    multipleChoiceQuestionOptions =
      multipleChoiceQuestionHelper.initialiseMultipleChoiceOptions(
        question,
        currentAnswer ? currentAnswer.answer : null,
        true
      );

    // Check the actor restrictions, and lock the question if it can't be answered by the current user
    const questionIsLockedForCurrentUser = !question.userCanAnswer(
      userContext.user.id,
      subjectUser.userId,
      isReadOnly,
      isInPrepMode
    );

    // Get the name to display in the read-only/locked question badge
    let otherParticipant = participants.find(
      (p) => p.userId != userContext.user.id
    );
    const otherUserDisplayName = userDetailsHelper.getDisplayName(
      otherParticipant?.userId!,
      participants
    );

    const questionComponent = (
      <CollabDocQuestionInput
        answerSetUniqueId={answerSetUniqueId}
        answerSetDateCreated={answerSetDateCreated}
        question={question}
        isReadOnly={isReadOnly}
        formActiveQuestionId={formActiveQuestionId}
        inputId={inputId}
        userId={userContext.user.id}
        currentAnswer={currentAnswer}
        multipleChoiceQuestionOptions={multipleChoiceQuestionOptions}
        showValidationErrors={showValidationErrors}
        subjectUser={subjectUser}
        validationResult={validationResult}
        tasks={tasks}
        usesAdvancedTaskTypes={userContext.user.client.taskTypes.length > 0}
        onChangeQuestionTasks={onChangeQuestionTasks}
        onMultipleChoiceQuestionValueChange={
          onMultipleChoiceQuestionValueChange
        }
        onValueChange={handleAnswerChange}
        isLockedForCurrentUser={questionIsLockedForCurrentUser}
        formColor={formColor}
        onValueSave={onValueSave}
        participants={participants}
        comments={comments}
        flaggedChanges={flaggedChanges}
        formComplexities={formComplexities}
        showPlanningResponses={showPlanningResponses}
        isInPrepMode={isInPrepMode}
        isPrinting={isPrinting}
        onEnforcedCommentChange={handleEnforcedCommentChange}
        onEnforcedCommentSave={handleEnforcedCommentSave}
        onRetryValueSave={onRetryValueSave}
        onRetryTasksSave={onRetryTasksSave}
        onRetryEnforcedCommentSave={onRetryEnforcedCommentSave}
        onChangeAdvancedTasks={onChangeAdvancedTasks}
      />
    );
    // Get any comments that went with the flagged change
    const commentsForQuestion = standardComments.filter(
      (x) => x.questionId === question.questionId
    );

    // Check whether the current question type has nested FlaggedChanges displayed,
    // so we can disable it at the top of the question component
    const disableFlaggedChangesAtThisLevel =
      question.questionType === "ADDTASK" ||
      question.questionType === "GOAL-SETTING" ||
      question.questionType === "BEHAVIOUR" ||
      question.questionType === "GOAL-REVIEW-STATUS-COMMENT";

    // If this question can display the flagged changes, load the data
    let singularFlaggedChange: CollabDocFlaggedChangeDto | null = null;
    let flaggedChangeComments: Array<SavedCommentDto> | null = null;
    if (
      !disableFlaggedChangesAtThisLevel &&
      flaggedChanges &&
      flaggedChanges.length > 0
    ) {
      singularFlaggedChange = flaggedChanges[0];
      if (singularFlaggedChange !== null && commentsForQuestion.length > 0) {
        flaggedChangeComments = commentsForQuestion.filter(
          (x) =>
            x.timestamp >= singularFlaggedChange!.timestamp &&
            x.authorId === singularFlaggedChange!.authorId
        );
      }
    }

    const isActiveQuestion =
      formActiveQuestionId !== null &&
      formActiveQuestionId === question.questionId;
    const containerClassName = isActiveQuestion
      ? "bg-gray-100/70"
      : "border-transparent";
    const questionDisplayText = questionTextHelper.getQuestionText(
      question,
      subjectUser,
      participants,
      userContext
    );

    const commentsList = (
      <CommentsList
        isReadOnly={isReadOnly}
        comments={standardComments}
        participants={participants}
        onDeleteComment={onCommentDelete}
        onTargetCommentForReply={setReplyToCommentId}
      />
    );

    const printModeCommentsElement =
      isPrinting && standardComments && standardComments.length > 0 ? (
        <div className="p-4 ml-2">
          <span className="block mb-1 text-sm font-semibold">Comments</span>
          {commentsList}
        </div>
      ) : null;

    // If it's a readonly question, just show the content
    if (question.questionType === "READONLY") {
      return (
        <div
          data-question-id={question.questionId}
          className="px-4 mt-2 m-4 font-semibold text-gray-600 text-lg"
        >
          <SafeRenderHtml
            htmlText={questionDisplayText}
            containerClassName="readonly-question"
            otherChildren={
              question.helpText ? (
                <MoreInfo
                  title={
                    question.helpTextTrigger ??
                    t("Forms.Generic.MoreInfoButtonText")
                  }
                  triggerText={question.helpTextTrigger}
                  content={question.helpText}
                  formType="COLLAB-DOC"
                />
              ) : undefined
            }
          />
          {printModeCommentsElement !== null && <>{printModeCommentsElement}</>}
        </div>
      );
    }

    const questionEditableByOneUserOnly =
      question.actorRestriction !== null &&
      (question.actorRestriction === "EMPLOYEE" ||
        question.actorRestriction === "MANAGER");

    return (
      <>
        <div
          data-question-id={question.questionId}
          className={cx(
            "px-4 py-4 border border-gray-300 rounded hover:bg-gray-100/70 group m-4",
            containerClassName
          )}
          onClick={() => onQuestionFocus(question.questionId)}
        >
          <div>
            <div className="flex">
              <div>
                <Label
                  htmlFor={inputId}
                  text={questionDisplayText}
                  className="font-semibold text-gray-600 text-lg"
                />

                {/* Desktop Only */}
                {(!question.validation.required ||
                  question.helpText ||
                  question.behaviourOptions?.behaviour.key ||
                  (questionIsLockedForCurrentUser && !isDocLocked)) && (
                  <span className="pl-1 hidden md:visible md:inline-block">
                    {!question.validation.required && (
                      <Badge
                        text={t("Common.Optional")}
                        backgroundColourClassName="bg-gray-200/75"
                        textColourClassName="text-gray-400"
                      />
                    )}
                    {question.helpText && (
                      <MoreInfo
                        title={questionDisplayText}
                        triggerText={question.helpTextTrigger}
                        content={question.helpText}
                        formType="COLLAB-DOC"
                      />
                    )}
                    {question.behaviourOptions?.behaviour.key && (
                      <BehaviourMoreInfo
                        behaviour={question.behaviourOptions!.behaviour}
                        infoTooltipContent={
                          question.behaviourOptions!.infoTooltipContent
                        }
                        formType="COLLAB-DOC"
                      />
                    )}
                    {questionIsLockedForCurrentUser && !isDocLocked && (
                      <QuestionReadonlyBadge
                        otherUserName={otherUserDisplayName}
                        isFormCompleted={
                          formApprovalStatus == "FULLY-APPROVED" ||
                          formApprovalStatus == "NO-APPROVAL-NECESSARY"
                        }
                        isInReadOnlyMode={isReadOnly}
                      />
                    )}
                  </span>
                )}
              </div>
              <div className="grow text-right">
                <div className="md:inline-block">
                  {/* Mobile indictor */}
                  <CommentIndicator
                    allowAddComment={!isReadOnly}
                    handleClick={() => onDisplayComments("MODAL")}
                    totalComments={standardComments.length}
                    unseenComments={unseenCommentCount}
                    className="md:hidden"
                  />
                  {/* Desktop indicator: */}
                  <CommentIndicator
                    allowAddComment={!isReadOnly}
                    handleClick={() => onDisplayComments("SIDEBAR")}
                    totalComments={standardComments.length}
                    unseenComments={unseenCommentCount}
                    className="hidden md:inline-block"
                  />
                </div>
              </div>
            </div>
            {/* Mobile Only */}
            <div className="visible md:hidden">
              {!question.validation.required && (
                <Badge
                  text={t("Common.Optional")}
                  backgroundColourClassName="bg-gray-200/75"
                  textColourClassName="text-gray-400"
                  marginClassName=""
                />
              )}
              {questionIsLockedForCurrentUser && !isDocLocked && (
                <QuestionReadonlyBadge
                  otherUserName={otherUserDisplayName}
                  isFormCompleted={
                    formApprovalStatus == "FULLY-APPROVED" ||
                    formApprovalStatus == "NO-APPROVAL-NECESSARY"
                  }
                  isInReadOnlyMode={isReadOnly}
                  classNames=""
                />
              )}
              {isPrinting && (
                <ActorRestrictionPrintLabel
                  restriction={question.actorRestriction}
                />
              )}
            </div>
          </div>
          {singularFlaggedChange && !disableFlaggedChangesAtThisLevel && (
            <CollabDocFlaggedChange
              change={singularFlaggedChange}
              loggedInUserId={userContext.user.id}
              participants={participants}
              relatedComments={flaggedChangeComments}
              question={question}
            />
          )}
          {question.behaviourOptions?.behaviour.key && (
            <>
              <BehaviourSubtext
                behaviour={question.behaviourOptions!.behaviour}
                subtextContent={question.behaviourOptions!.subtextContent}
                classNames="text-gray-600"
              />
            </>
          )}
          <hr className="flex-grow my-1"></hr>
          <div className="pl-2">{questionComponent}</div>
          {showAddCommentSuggestion &&
            isActiveQuestion &&
            !questionEditableByOneUserOnly && (
              <div className="py-1 px-2 text-sm text-gray-400">
                {t(
                  "Pages.CollaborativeDocument.Controls.AddCommentSuggestionPrefix"
                )}{" "}
                <button
                  className="hidden md:inline-block underline font-bold"
                  onClick={() => onDisplayComments("SIDEBAR")}
                >
                  {t(
                    "Pages.CollaborativeDocument.Controls.AddCommentSuggestionLink"
                  )}
                  <FontAwesomeIcon
                    icon={faCommentPlus}
                    size="1x"
                    className="pl-1"
                  />
                </button>
                <button
                  className="md:hidden underline font-bold"
                  onClick={() => onDisplayComments("MODAL")}
                >
                  {t(
                    "Pages.CollaborativeDocument.Controls.AddCommentSuggestionLink"
                  )}
                  <FontAwesomeIcon
                    icon={faCommentPlus}
                    size="1x"
                    className="pl-1"
                  />
                </button>{" "}
                {t(
                  "Pages.CollaborativeDocument.Controls.AddCommentSuggestionSuffix"
                )}
              </div>
            )}
        </div>
        {/* Modal (mobile): */}
        <ModalPopup
          isOpen={modalIsOpen}
          onOpenChange={setModalIsOpen}
          onPrimaryButtonClick={() => setModalIsOpen(false)}
          primaryButtonText={t("Common.Close")}
          title={t("Pages.CollaborativeDocument.Common.Comments")}
        >
          {commentsList}
          {!isReadOnly && (
            <NewCommentForm
              questionId={question.questionId}
              replyToCommentId={replyToCommentId}
              onSubmit={onCommentAdd}
              inputRef={commentFormInputRef}
            />
          )}
        </ModalPopup>
        {printModeCommentsElement}
      </>
    );
  },
  collabDocComponentPropsAreEqual
);
export default CollabDocQuestion;
