import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import taskTypeIconHelper from "../../helpers/taskTypeIconHelper";
import ClientTaskTypeIcon from "../../types/dtos/tasks/advanced-tasks/ClientTaskTypeIcon";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import UserTaskListGroupDto from "../../types/dtos/tasks/advanced-tasks/UserTaskListGroupDto";
import { AdvancedTaskStatus } from "../../types/dtos/tasks/advanced-tasks/AdvancedTaskStatus";
import { advancedTaskHelper } from "../../helpers";

interface EmptyTaskTabStateProps {
  clientColour: string;
  iconType: ClientTaskTypeIcon;
  tabType: AdvancedTaskStatus;
  singularTaskTypeNameIdentifier: string;
  pluralTaskTypeNameIdentifier: string;
  isActiveTabSelected: boolean;
  userGroup?: UserTaskListGroupDto;
  onAddNewTaskClick: () => void;
}

const EmptyTaskTabState = ({
  clientColour,
  iconType,
  tabType,
  singularTaskTypeNameIdentifier,
  pluralTaskTypeNameIdentifier,
  isActiveTabSelected,
  userGroup,
  onAddNewTaskClick,
}: EmptyTaskTabStateProps) => {
  const { t } = useTranslation();

  const emptyStateTranslationKey = userGroup
    ? "TaskType.EmptyState.HasntGotAnyX"
    : "TaskType.EmptyState.YouHaventGotAnyX";
  const emptyStateText = t(emptyStateTranslationKey)
    .replace("#PERSON#", userGroup ? userGroup.employeeFirstName : "")
    .replace("#STATUS#", tabType.toLowerCase())
    .replace("#TASK_TYPE#", advancedTaskHelper.ToLowerCase(pluralTaskTypeNameIdentifier));

  return (
    <div className="text-center text-gray-400 grow pt-6">
      <div className="my-2">
        <FontAwesomeIcon
          color={"#D1D5DB"}
          size="4x"
          icon={taskTypeIconHelper.getTaskTypeIcon(iconType)}
        />
      </div>
      <p className="mt-1 text-lg">
        <span className="text-sm mb-4">{emptyStateText}</span>
      </p>
      {isActiveTabSelected && (
        <p className="mt-2 text-center">
          <button
            className="btn-primary !px-3 !py-2"
            onClick={onAddNewTaskClick}
          >
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
            <span className="ml-1">
              {t("TaskType.Button.AddNewX").replace(
                "#TASK_TYPE#",
                advancedTaskHelper.ToLowerCase(singularTaskTypeNameIdentifier)
              )}
            </span>
          </button>
        </p>
      )}
    </div>
  );
};

export default EmptyTaskTabState;
