import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faInfo } from "@fortawesome/pro-solid-svg-icons";
import cx from "classnames";

interface InfoBannerProps {
  prefix?: string;
  text?: string;
  children?: React.ReactNode;
  className?: string;
  textClassName?: string;
  margin?: string;
}

/** Renders the children, if supplied, otherwise falls back to displaying the text prop */
function InfoBanner({
  prefix,
  text,
  children,
  className,
  textClassName = "text-sm",
  margin = "mb-2",
}: InfoBannerProps) {
  const isUsingChildrenElements = children != null && children != undefined;
  return (
    <div
      className={cx(
        "py-1 px-4 bg-blue-50 text-blue-500 rounded-y-md border-l-2 border-l-blue-500",
        className,
        textClassName,
        margin,
        isUsingChildrenElements ? "flex" : ""
      )}
    >
      <div
        className={cx(
          "fa-layers fa-fw mr-1",
          isUsingChildrenElements ? "mt-1" : ""
        )}
      >
        <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
        <FontAwesomeIcon icon={faInfo} size="xs" className="text-white" />
      </div>
      {prefix && <span className="font-semibold mr-1">{prefix}</span>}
      {children ? children : text}
    </div>
  );
}

export default InfoBanner;
