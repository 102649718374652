import { Link, useParams } from "react-router-dom";
import { MainContainer } from "../../components/layout";
import { Reducer, useReducer, useContext, useEffect } from "react";
import UserContext from "../../state/UserContext";
import { ClientTaskType } from "../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import AppContext from "../../state/AppContext";
import { useTranslation } from "react-i18next";
import AppRoutes from "../AppRoutes";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TaskTypeHeaderBanner from "../../components/task-management/TaskTypeHeaderBanner";
import advancedTasksApi from "../../api/task/advancedTasksApi";
import { useAuth } from "react-oidc-context";
import UserTaskListingForOwnerResponse from "../../types/dtos/tasks/advanced-tasks/UserTaskListingForOwnerResponse";
import UserTaskListItemDto from "../../types/dtos/tasks/advanced-tasks/UserTaskListItemDto";
import SmallLoader from "../../components/loaders/SmallLoader";
import { ClientTaskTypeCategory } from "../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { ClientTaskTypeInactiveCategory } from "../../types/dtos/tasks/advanced-tasks/ClientTaskTypeInactiveCategory";
import { CountRestrictionType } from "../../types/dtos/tasks/advanced-tasks/CountRestrictionType";
import TaskManagementSection from "../../components/task-management/TaskManagementSection";
import { AdvancedTaskTabCounts } from "../../types/dtos/tasks/advanced-tasks/AdvancedTaskTabCounts";
import userApi from "../../api/user/userApi";
import ResultStatus from "../../types/dtos/generic/ResultStatus";
import { TaskReviewDetailDto } from "../../types/dtos/tasks/advanced-tasks/TaskReviewDetailDto";

interface IndividualTaskManagementState {
  isPageLoading: boolean;
  tabCounts: AdvancedTaskTabCounts;
  taskType: ClientTaskType | null;
  headerTitleHtml: string | null;
  headerBodyHtml: string | null;
  tasks: UserTaskListItemDto[];
  minTasks: number | null;
  maxTasks: number | null;
  countRestriction: CountRestrictionType;
  activeCategories: ClientTaskTypeCategory[] | null;
  inactiveCategories: ClientTaskTypeInactiveCategory[] | null;
  isActivelyInReview: boolean,
  taskReviewDetails: TaskReviewDetailDto | null;
}

function IndividualTaskManagement() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);
  const usersApi = new userApi(auth.user?.access_token);

  const { taskTypeId, taskId } = useParams();

  const [state, setState] = useReducer<
    Reducer<
      IndividualTaskManagementState,
      Partial<IndividualTaskManagementState>
    >
  >((state, newState) => ({ ...state, ...newState }), {
    isPageLoading: false,
    taskType: null,
    headerTitleHtml: null,
    headerBodyHtml: null,
    tasks: [],
    minTasks: null,
    maxTasks: null,
    countRestriction: "NONE",
    activeCategories: null,
    inactiveCategories: null,
    tabCounts: {
      employeeId: 0,
      active: 0,
      cancelled: 0,
      completed: 0,
      historic: 0,
    },
    isActivelyInReview: false,
    taskReviewDetails: null,
  });

  useEffect(() => {
    setState({ isPageLoading: true });
    const clientTaskType = userContext.user.client.taskTypes.find(
      (x) => x.id === taskTypeId
    );
    if (clientTaskType) {
      appContext.setPageTitle(
        t("Pages.MyDashboard.PageTitle") +
        ": " +
        t(clientTaskType.pluralNameTranslationKeyIdentifier)
      );
      tasksApi.listTasksForOwner(
        clientTaskType.id,
        (data: UserTaskListingForOwnerResponse) => {
          setState({
            taskType: clientTaskType,
            headerTitleHtml: data.headerTitleHtml,
            headerBodyHtml: data.headerBodyHtml,
            // @ts-ignore - Sort the order of the tasks for 'Active' tab
            tasks:
              data.tasks != null
                ? data.tasks.sort(
                  (a, b) =>
                    new Date(a.targetDate).getTime() -
                    new Date(b.targetDate).getTime()
                )
                : [],
            isPageLoading: false,
            minTasks: data.minTasks,
            maxTasks: data.maxTasks,
            countRestriction: data.countRestriction,
            activeCategories: data.activeCategories,
            inactiveCategories: data.inactiveCategories,
            tabCounts: data.tabCounts,
            isActivelyInReview: data.isActivelyInReview,
            taskReviewDetails: data.taskReviewDetails,
          });
        },
        (error: any) => {
          console.error("Error getting initial tasks", error);
        }
      );
    }
  }, [taskTypeId]);

  const updateTasks = (
    tasks: UserTaskListItemDto[],
    tabCounts: AdvancedTaskTabCounts
  ) => {
    setState({ ...state, tasks: tasks, tabCounts: tabCounts });
  };

  const onClearNotifications = (userId: number, tasks: UserTaskListItemDto[]) => {
    if (state.taskType !== null) {
      tasksApi.clearAllTaskHighlightsForUser(
        state.taskType.id,
        userId,
        (data: ResultStatus) => {
          // Clear the flags against the tasks in state (so the UI updates)
          tasks.forEach((task) => {
            task.highlightToOwner = false;
          });

          // Update the logged in user details
          usersApi.getRefreshedLoggedInUserDetails(
            userContext.setUpdatedUserDetails,
            () => { }
          );
        },
        (error: any) => {
          console.error("Error clearing notifications", error);
        }
      );
    }
  };

  return (
    <MainContainer>
      <div className="pt-2">
        <Link to={AppRoutes.yourJourney.root} className="btn-primary">
          <FontAwesomeIcon size="xs" icon={faChevronLeft} />
          <span className="pl-1.5">{t("Common.Back")}</span>
        </Link>
      </div>
      {state.isPageLoading && (
        <div className="pt-2">
          <SmallLoader />
          <p className="text-center pt-1 text-[#959595] text-sm">
            {t("Common.Loading")}...
          </p>
        </div>
      )}
      {!state.isPageLoading && state.taskType && (
        <div>
          {state.headerTitleHtml && state.headerBodyHtml && (
            <>
              <TaskTypeHeaderBanner
                title={state.headerTitleHtml}
                body={state.headerBodyHtml}
              />
              <hr className="mt-4" />
            </>
          )}
          {state.tasks.find((x) => x.highlightToOwner === true) && (
            <div className="flex flex-row justify-end mt-2">
              <button
                className="btn-secondary border-gray-300 text-[#5d5d5d] text-xs"
                onClick={() => onClearNotifications(userContext.user.id, state.tasks)}
              >
                Clear notifications
              </button>
            </div>
          )}
          <TaskManagementSection
            tabCounts={state.tabCounts}
            userContext={userContext}
            taskType={state.taskType}
            tasks={state.tasks}
            minTasks={state.minTasks}
            maxTasks={state.maxTasks}
            countRestriction={state.countRestriction}
            activeCategories={state.activeCategories}
            userTaskIdFromUrl={taskId}
            isTeamTaskManagement={false}
            setTasks={updateTasks}
            isActivelyInReview={state.isActivelyInReview}
            taskReviewDetails={state.taskReviewDetails}
          />
        </div>
      )}
    </MainContainer>
  );
}

export default IndividualTaskManagement;
