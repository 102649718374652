import { ActorRestriction } from "../../types/forms";
import { Badge } from "../common";

interface ActorRestrictionPrintLabelProps {
  restriction: ActorRestriction;
}

function ActorRestrictionPrintLabel({
  restriction,
}: ActorRestrictionPrintLabelProps) {
  if (
    !restriction ||
    restriction === "NONE" ||
    restriction === "FULLY-READONLY"
  ) {
    return null;
  }
  const actorNameToDisplay = restriction === "MANAGER" ? "Manager" : "Employee";
  return (
    <span className="text-xs py-1 text-gray-600 inline-block mx-2">{`(${actorNameToDisplay} question)`}</span>
  );
}

export default ActorRestrictionPrintLabel;
