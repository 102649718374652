import { t } from "i18next";
import { Tooltip } from "../../../common";
import { NineBoxGridBoxData } from "../../../../types/analytics/charts/NineBoxGrids";
import { colourHelper, mathsHelper } from "../../../../helpers";

interface NineBoxGridBoxProps {
  box: NineBoxGridBoxData;
  gridBoxesCount: number;
  customWidgetColoursCsv: string | undefined;
  onDrilldown: (boxNumber: number) => void;
}

// Found this useful for picking colours: https://htmlcolorcodes.com/colors/shades-of-green/
const colours9bg = [
  "#FF4433",
  "#F88379",
  "#E4D00A",
  "#E97451",
  "#E3963E",
  "#AFE1AF",
  "#C4B454",
  "#B4C424",
  "#00A36C",
];

const colours16bg = [
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
  "#ccc",
];

function getBoxColourHexCode(
  boxNumber: number,
  totalBoxes: number,
  customWidgetColoursCsv: string | undefined
) {
  let coloursArray =
    totalBoxes === 9
      ? colours9bg
      : totalBoxes === 16
      ? colours16bg
      : [
          /* If needed, define other colour arrays for other grid sizes */
        ];

  // If custom colours are provided, use them instead of the default colours
  if (customWidgetColoursCsv && customWidgetColoursCsv.length > 0) {
    const customColours = customWidgetColoursCsv.split(",");
    if (
      customColours.every(
        (c) => c !== null && c.length > 0 && c.startsWith("#")
      )
    ) {
      coloursArray = customColours;
    }
  }

  if (boxNumber > coloursArray.length) {
    return "#ccc";
  }
  return coloursArray[boxNumber - 1];
}

function NineBoxGridBox({
  box,
  gridBoxesCount,
  customWidgetColoursCsv,
  onDrilldown,
}: NineBoxGridBoxProps) {
  const integerPercentage = mathsHelper.roundForDisplay(box.percentage, 0);
  const tooltipContent = `${box.count} (${mathsHelper.roundForDisplay(
    box.percentage,
    3
  )}%)`;
  const peopleText = box.count === 1 ? "person" : "people";
  const bgColourHexCode = getBoxColourHexCode(
    box.number,
    gridBoxesCount,
    customWidgetColoursCsv
  );

  const handleBoxClick = () => {
    if (box.count > 0) {
      onDrilldown(box.number);
    }
  };

  const defaultTextColour = "#1f2937";
  const textContrastOk = colourHelper.doesColourPassContrastCheck(
    defaultTextColour,
    bgColourHexCode
  );
  const textColourClassName = !textContrastOk ? "text-white" : "text-gray-800";

  return (
    <div
      className={`flex-1 text-center items-center justify-center ${textColourClassName}`}
      style={{ backgroundColor: bgColourHexCode }}
    >
      <div
        role="button"
        className={box.count > 0 ? "cursor-pointer" : "cursor-not-allowed"}
        onClick={handleBoxClick}
      >
        <div className="xl:text-lg font-semibold mb-2 bg-white/20 px-2 py-1">
          {t(`Analytics.Widgets.NineBoxGrid.BoxTitles.Box${box.number}`)}
        </div>

        <div>
          <Tooltip
            content={tooltipContent}
            triggerElement={
              <div className="font-semibold text-gray-800 rounded-full bg-white flex items-center justify-center w-12 h-12 text-lg mx-auto mt-4">
                {integerPercentage}
                <span className="text-xs">%</span>
              </div>
            }
          />
        </div>
        <div className="mb-4">
          {box.count} {peopleText}
        </div>
      </div>
    </div>
  );
}
export default NineBoxGridBox;
