import { Link, useParams } from "react-router-dom";
import { MainContainer } from "../../components/layout";
import {
  Fragment,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import UserContext from "../../state/UserContext";
import { ClientTaskType } from "../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import AppContext from "../../state/AppContext";
import { useTranslation } from "react-i18next";
import AppRoutes from "../AppRoutes";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserTaskListGroupDto from "../../types/dtos/tasks/advanced-tasks/UserTaskListGroupDto";
import { useAuth } from "react-oidc-context";
import advancedTasksApi from "../../api/task/advancedTasksApi";
import UserTaskListingForManagerResponse from "../../types/dtos/tasks/advanced-tasks/UserTaskListingForManagerResponse";
import SmallLoader from "../../components/loaders/SmallLoader";
import TaskTypeHeaderBanner from "../../components/task-management/TaskTypeHeaderBanner";
import TaskManagementSection from "../../components/task-management/TaskManagementSection";
import UserTaskListItemDto from "../../types/dtos/tasks/advanced-tasks/UserTaskListItemDto";
import SearchField from "../../components/common/SearchField";
import EmptyFilteredState from "../../images/EmptyFilteredState";
import { Avatar } from "../../components/common";
import UserTagList from "../../components/manager-dashboard/Tags/UserTagList";
import { AdvancedTaskTabCounts } from "../../types/dtos/tasks/advanced-tasks/AdvancedTaskTabCounts";
import ResultStatus from "../../types/dtos/generic/ResultStatus";
import userApi from "../../api/user/userApi";

interface TeamTaskManagementState {
  isPageLoading: boolean;
  taskType: ClientTaskType | null;
  headerTitleHtml: string | null;
  headerBodyHtml: string | null;
  users: UserTaskListGroupDto[];
  filteredUsers: UserTaskListGroupDto[];
}

function TeamTaskManagement() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);
  const usersApi = new userApi(auth.user?.access_token);

  const { taskTypeId, preselectedEmployeeId } = useParams();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [state, setState] = useReducer<
    Reducer<TeamTaskManagementState, Partial<TeamTaskManagementState>>
  >((state, newState) => ({ ...state, ...newState }), {
    isPageLoading: false,
    taskType: null,
    headerTitleHtml: null,
    headerBodyHtml: null,
    users: [],
    filteredUsers: [],
  });

  useEffect(() => {
    setState({ isPageLoading: true });
    const clientTaskType = userContext.user.client.taskTypes.find(
      (x) => x.id === taskTypeId
    );
    if (clientTaskType) {
      appContext.setPageTitle(
        t("Pages.ManagerDashboard.PageTitle") +
          ": " +
          t(clientTaskType.pluralNameTranslationKeyIdentifier)
      );

      tasksApi.listTasksForManager(
        clientTaskType.id,
        (data: UserTaskListingForManagerResponse) => {
          setState({
            isPageLoading: false,
            taskType: clientTaskType,
            headerTitleHtml: data.headerTitleHtml,
            headerBodyHtml: data.headerBodyHtml,
            users: data.users,
            filteredUsers: data.users,
          });

          if (preselectedEmployeeId && data.users.length > 0) {
            populateSearchBarWithPreselectedEmployee(data.users);
          }
        },
        (error: any) => {
          console.error("Error getting comments", error);
        }
      );
    }
  }, [taskTypeId]);

  const populateSearchBarWithPreselectedEmployee = (
    users: UserTaskListGroupDto[]
  ) => {
    const preselectedEmployeeName = users.find(
      (x) => x.employeeId == parseInt(preselectedEmployeeId!)
    )?.employeeFullName;
    if (preselectedEmployeeName != null) {
      setSearchTerm(preselectedEmployeeName);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      let users = [...state.users];
      users = users.filter((x) =>
        x.employeeFullName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setState({ filteredUsers: users });
    } else {
      setState({ filteredUsers: state.users });
    }
  }, [searchTerm]);

  const handleSearchTermChange = (newsearchTerm: string | null) => {
    setSearchTerm(newsearchTerm);
  };

  const updateTasks = (
    userId: number,
    tasks: UserTaskListItemDto[],
    tabCounts: AdvancedTaskTabCounts
  ) => {
    const users = [...state.users];
    const index = users.findIndex((x) => x.employeeId == userId);
    users[index].tasks = tasks;
    users[index].tabCounts = tabCounts;
    setState({ users: users });
  };

  const onClearNotifications = (
    userId: number,
    tasks: UserTaskListItemDto[]
  ) => {
    if (state.taskType !== null) {
      tasksApi.clearAllTaskHighlightsForUser(
        state.taskType.id,
        userId,
        (data: ResultStatus) => {
          // Clear the flags against the tasks in state (so the UI updates)
          tasks.forEach((task) => {
            task.highlightToManager = false;
          });

          // Update the logged in user details
          usersApi.getRefreshedLoggedInUserDetails(
            userContext.setUpdatedUserDetails,
            () => {}
          );
        },
        (error: any) => {
          console.error("Error clearing notifications", error);
        }
      );
    }
  };

  return (
    <MainContainer>
      <div className="pt-2">
        <Link to={AppRoutes.yourPeople.root} className="btn-primary">
          <FontAwesomeIcon size="xs" icon={faChevronLeft} />
          <span className="pl-1.5">{t("Common.Back")}</span>
        </Link>
      </div>
      {state.isPageLoading && (
        <div className="pt-2">
          <SmallLoader />
          <p className="text-center pt-1 text-[#959595] text-sm">
            {t("Common.Loading")}...
          </p>
        </div>
      )}
      {!state.isPageLoading && state.taskType != null && (
        <div>
          {state.headerTitleHtml && state.headerBodyHtml && (
            <>
              <div>
                <TaskTypeHeaderBanner
                  title={state.headerTitleHtml}
                  body={state.headerBodyHtml}
                />
              </div>
              <hr className="mt-4" />
            </>
          )}
          <div>
            <div className="mt-3 flex flex-row justify-end">
              <SearchField
                onSearch={handleSearchTermChange}
                isLoading={state.isPageLoading}
                loaderColor={userContext.user.client.accentHexColour}
                searchTerm={searchTerm}
                includeSetWidth={true}
              />
            </div>
            {!state.isPageLoading && state.filteredUsers.length === 0 && (
              <em className="block text-center text-sm py-4">
                <div className="mb-4 flex justify-center">
                  <EmptyFilteredState />
                </div>
                {t("Pages.Admin.Common.NoUsersMatchingCriteria")}
              </em>
            )}
            {state.filteredUsers.map((user) => (
              <Fragment key={user.employeeId}>
                <div className="mt-4 px-4">
                  <div className="grid grid-cols-1 gap-1 md:mb-1">
                    <div className="flex flex-row">
                      <div className="hidden md:block md:mr-5 md:ml-1 md:mt-1">
                        <Avatar
                          userInitials={user.initials}
                          userFullName={user.employeeFullName}
                          activeStatus="DO-NOT-SHOW"
                          imageUrl={user.profileImageUrl}
                          size={12}
                        />
                      </div>
                      <div className="flex flex-col grow">
                        <h4 className="coloured-heading !mb-0 !mt-0 flex-none text-xl">
                          {user.employeeFullName}
                        </h4>
                        {/* Tags */}
                        <div className="mt-1">
                          <UserTagList
                            jobTitle={user.jobTitleTranslationKeyIdentifier}
                            jobTitleTooltip={t("Pages.Admin.Common.JobTitle")}
                            appraisalLevel={
                              user.appraisalLevelTranslationKeyIdentifier
                            }
                            appraisalLevelTooltip={t("Common.AppraisalLevel")}
                            location={user.locationName}
                            locationTooltip={t("Pages.Admin.Common.Location")}
                            viewingActor={
                              userContext.user.id === user.managerId ? "MANAGER" : "DELEGATED-MANAGER"
                            }
                            managerOrDelegatedName={
                              userContext.user.id === user.managerId
                                ? user.journeyManagerFullName
                                : user.managerFullName
                            }
                          />
                        </div>
                      </div>
                      {user.tasks.find(
                        (x) => x.highlightToManager === true
                      ) && (
                        <div className="shrink-0 justify-self-end">
                          <button
                            className="btn-secondary border-gray-300 text-[#5d5d5d] text-xs"
                            onClick={() =>
                              onClearNotifications(user.employeeId, user.tasks)
                            }
                          >
                            Clear notifications
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <TaskManagementSection
                    tabCounts={user.tabCounts}
                    userContext={userContext}
                    taskType={state.taskType!}
                    tasks={user.tasks}
                    minTasks={user.minTasks}
                    maxTasks={user.maxTasks}
                    countRestriction={user.countRestriction}
                    activeCategories={user.activeCategories}
                    userGroup={user}
                    isTeamTaskManagement={true}
                    setUserTasks={updateTasks}
                    isActivelyInReview={user.isActivelyInReview}
                    taskReviewDetails={user.taskReviewDetails}
                  />
                </div>
                <hr className="mt-4" />
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </MainContainer>
  );
}

export default TeamTaskManagement;
