import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import UserContext from "../../../../state/UserContext";
import advancedTasksApi from "../../../../api/task/advancedTasksApi";
import { UserTaskDetail } from "../../../../types/dtos/tasks/advanced-tasks/UserTaskDetail";
import {
  FormattedDate,
  ScrollableModalContentTemplate,
  SummaryTable,
} from "../../../common";
import {
  SimpleFormAnswer,
  SimpleFormContent,
  SimpleFormValidationError,
} from "../../../../types/dtos/simple-forms";
import ResultStatus from "../../../../types/dtos/generic/ResultStatus";
import SimpleForm from "../../../simple-forms/editable/SimpleForm";
import { advancedTaskHelper, simpleFormHelper } from "../../../../helpers";
import { UserTaskSaveResponse } from "../../../../types/dtos/tasks/advanced-tasks/api-response/UserTaskSaveResponse";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import DangerAlert from "../../../alerts/DangerAlert";
import LeavingWarningFooter from "../LeavingWarningFooter";

interface AdvancedTaskCancelViewProps {
  taskType: ClientTaskType | null,
  details: UserTaskDetail;
  userIsTryingToClosePopup: boolean;
  /** Whether or not this view has been launched directly in the popup, or from the main task details tab's "Complete" button  */
  viewLoadedDirectly: boolean;
  goBackToBodyDetails(): void;
  goBackFromWarning(): void;
  onCancelled(): void;
  /** When loaded directly (e.g. from the collab doc) and clicking the go back option, need to do check to show warning */
  onDirectBackCancelled(): void;
  setFormIsDirty(): void;
  proceedAfterWarning(): void;
}

const AdvancedTaskCancelView = ({
  taskType,
  details,
  userIsTryingToClosePopup,
  viewLoadedDirectly,
  goBackFromWarning,
  goBackToBodyDetails,
  onCancelled,
  onDirectBackCancelled,
  setFormIsDirty,
  proceedAfterWarning,
}: AdvancedTaskCancelViewProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [viewIsReady, setViewIsReady] = useState<boolean>(false);
  const [formContent, setFormContent] = useState<SimpleFormContent | null>(
    null
  );
  const [formAnswers, setFormAnswers] = useState<SimpleFormAnswer[]>([]);
  const [validationErrors, setValidationErrors] = useState<
    SimpleFormValidationError[] | null
  >(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const matchingTaskType = userContext.user.client.taskTypes.find(
    (tt) => tt.id === details.taskTypeId
  );

  const onGoBackClick = () => {
    // Either go back to the main task details tab, or close the popup (as appropriate)
    if (viewLoadedDirectly) {
      onDirectBackCancelled();
    } else {
      goBackToBodyDetails();
    }
  };

  useEffect(() => {
    setViewIsReady(false);
    if (!details.userTaskId) return;

    // If there's no cancel form, we just show a "Are you sure" UI
    if (!matchingTaskType?.cancelFormId) {
      setViewIsReady(true);
    } else {
      // Load the form content
      loadFormFromApi(details.userTaskId);
    }
  }, [details.userTaskId]);

  function loadFormFromApi(userTaskId: string) {
    const onFormLoaded = (data: SimpleFormContent | null) => {
      setFormContent(data);
      // If there's an existing cancel form response, load the answers
      setFormAnswers(details.cancelFormInstance?.response.answers || []);
      setViewIsReady(true);
    };

    const onFormLoadError = (error: any) => {
      console.error("Unable to load cancel form", error);
    };

    tasksApi.getCancelForm(userTaskId, onFormLoaded, onFormLoadError);
  }

  const handleFormAnswerChange = (newAnswer: SimpleFormAnswer) => {
    const newAnswers = simpleFormHelper.updateAnswerState(
      newAnswer,
      formAnswers
    );
    setFormIsDirty();
    setFormAnswers(newAnswers);
  };

  const scrollToTop = () => {
    if (!scrollableContainerRef.current) return;
    scrollableContainerRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onCancelWithoutForm = () => {
    // No simple form to fill out, just mark this goal as cancelled
    if (!details.userTaskId) return;

    const onCancelError = (error: any) => {
      setIsSaving(false);
      scrollToTop();
      console.error("Unable to cancel task", error);
    };

    const onCancelSuccess = (data: ResultStatus) => {
      setIsSaving(false);
      if (data.success) {
        onCancelled();
      } else {
        onCancelError(data.message);
      }
    };

    setIsSaving(true);
    tasksApi.cancelTaskWithoutForm(
      details.userTaskId,
      onCancelSuccess,
      onCancelError
    );
  };

  const onCancelWithForm = () => {
    // There's a form to fill out, so validate then post the response
    if (!details.userTaskId) return;

    const onCancelError = (error: any) => {
      setIsSaving(false);
      scrollToTop();
      console.error("Unable to cancel task", error);
    };

    const onCancelSuccess = (data: UserTaskSaveResponse) => {
      setIsSaving(false);
      setValidationErrors(null);
      if (data.savedSuccessfully) {
        onCancelled();
      } else if (data.validationErrors && data.validationErrors.length > 0) {
        setValidationErrors(data.validationErrors);
      } else {
        onCancelError(data.errorReason);
      }
    };

    // Reset validation errors
    setValidationErrors(null);

    // Post the response
    setIsSaving(true);
    tasksApi.cancelTaskWithForm(
      {
        userTaskId: details.userTaskId,
        formId: formContent!.formId,
        answers: formAnswers,
        responseId: details.cancelFormInstance?.response.responseId || null,
        questionIdsToPullCommentsFrom: matchingTaskType?.commentQuestionIds || [],
      },
      onCancelSuccess,
      onCancelError
    );
  };

  // Don't render anything until the view is marked as ready
  if (!matchingTaskType || !viewIsReady) return null;

  const singularTaskTypeName = advancedTaskHelper.ToLowerCase(
    matchingTaskType.singularNameTranslationKeyIdentifier
  );

  let bodyContent: JSX.Element;
  let footerContent: JSX.Element;

  // If there's a cancel form, show it, otherwise just do a "Are you sure..." interface
  if (matchingTaskType.cancelFormId && formContent) {
    // There is a Cancel Form that we can display
    // Check if the user is trying to close the popup and if they are show a warning else show the form
    bodyContent =
      userIsTryingToClosePopup ? (
        // Cancel Form Warning Content
        <DangerAlert
          prefix=""
          message={t("TaskType.Popup.Warning.ChangesMaybeLost")}
        />
      ) : (
        // Cancel Form Content
        <SimpleForm
          formContent={formContent}
          answers={formAnswers}
          onChange={handleFormAnswerChange}
          loggedInUser={userContext.user}
          subjectUser={details.ownerEmployee}
          validationErrors={validationErrors}
        />
      );
    footerContent =
      userIsTryingToClosePopup ? (
        // Cancel Form Warning Footer
        <LeavingWarningFooter
          goBackFromWarning={goBackFromWarning}
          proceedAfterWarning={proceedAfterWarning}
        />
      ) : (
        // Cancel Form Footer
        <div className="flex flex-row justify-end gap-4">
          <button onClick={onGoBackClick} className="hover:underline">
            {t("TaskType.Popup.Buttons.GoBack")}
          </button>
          <button
            onClick={onCancelWithForm}
            className="btn-primary"
            disabled={isSaving}
          >
            {t("TaskType.Popup.Buttons.Confirm")}
          </button>
        </div>
      );
  } else {
    // No simple form for cancelling a task, so show a simple "Are you sure?" interface
    bodyContent = (
      <>
        <p className="my-2">
          {t("TaskType.Popup.CancelView.Intro", {
            taskType: singularTaskTypeName,
          })}
        </p>
        <SummaryTable
          containerClassNames="mb-2"
          rows={[
            {
              name: taskType?.titleTranslationKeyIdentifier != null
                ? t(taskType.titleTranslationKeyIdentifier)
                : t("TaskType.Popup.Labels.Description"),
              value: details.title,
            },
            {
              name: t("TaskType.Popup.Labels.DueDate"),
              value: (
                <FormattedDate
                  date={details.targetDate}
                  displayMode="DATE-ONLY"
                />
              ),
            },
          ]}
        />
      </>
    );
    footerContent = (
      <div className="flex flex-row justify-end gap-4">
        <button onClick={goBackToBodyDetails} className="hover:underline">
          {t("TaskType.Popup.Buttons.GoBack")}
        </button>
        <button
          onClick={onCancelWithoutForm}
          className="btn-primary"
          disabled={isSaving}
        >
          {t("TaskType.Popup.Buttons.Confirm")}
        </button>
      </div>
    );
  }

  return (
    <ScrollableModalContentTemplate
      footer={footerContent}
      bodyRef={scrollableContainerRef}
    >
      <div className="pt-2">{bodyContent}</div>
    </ScrollableModalContentTemplate>
  );
};

export default AdvancedTaskCancelView;
