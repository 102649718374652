import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import UserContext from "../../../../state/UserContext";
import advancedTasksApi from "../../../../api/task/advancedTasksApi";
import { UserTaskDetail } from "../../../../types/dtos/tasks/advanced-tasks/UserTaskDetail";
import {
  FormattedDate,
  ScrollableModalContentTemplate,
  SummaryTable,
} from "../../../common";
import {
  SimpleFormAnswer,
  SimpleFormContent,
  SimpleFormValidationError,
} from "../../../../types/dtos/simple-forms";
import ResultStatus from "../../../../types/dtos/generic/ResultStatus";
import SimpleForm from "../../../simple-forms/editable/SimpleForm";
import { advancedTaskHelper, simpleFormHelper } from "../../../../helpers";
import { UserTaskSaveResponse } from "../../../../types/dtos/tasks/advanced-tasks/api-response/UserTaskSaveResponse";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import TaskDetailsSection from "./TaskDetailsSection";
import { ClientTaskTypeCategory } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import DangerAlert from "../../../alerts/DangerAlert";
import LeavingWarningFooter from "../LeavingWarningFooter";
import { TaskEditPopupUsageScenario } from "../../../../types/tasks/TaskEditPopupUsageScenario";

interface AdvancedTaskCloseViewProps {
  taskType: ClientTaskType | null;
  details: UserTaskDetail;
  categories: ClientTaskTypeCategory[];
  /** Whether or not this view has been launched directly in the popup, or from the main task details tab's "Complete" button  */
  viewLoadedDirectly: boolean;
  userIsTryingToClosePopup: boolean;
  usageScenario: TaskEditPopupUsageScenario,
  goBackToBodyDetails(): void;
  goBackFromWarning(): void;
  onClosed(): void;
  /** When loaded directly (e.g. from the collab doc) and clicking the go back option, need to do check to show warning */
  onDirectBackClosed(): void;
  setFormIsDirty(): void
  proceedAfterWarning(): void;
}

const AdvancedTaskCloseView = ({
  taskType,
  details,
  categories,
  viewLoadedDirectly,
  userIsTryingToClosePopup,
  usageScenario,
  goBackToBodyDetails,
  goBackFromWarning,
  onClosed,
  onDirectBackClosed,
  setFormIsDirty,
  proceedAfterWarning,
}: AdvancedTaskCloseViewProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [viewIsReady, setViewIsReady] = useState<boolean>(false);
  const [formContent, setFormContent] = useState<SimpleFormContent | null>(
    null
  );
  const [formAnswers, setFormAnswers] = useState<SimpleFormAnswer[]>([]);
  const [validationErrors, setValidationErrors] = useState<
    SimpleFormValidationError[] | null
  >(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const matchingTaskType = userContext.user.client.taskTypes.find(
    (tt) => tt.id === details.taskTypeId
  );

  const onGoBackClick = () => {
    // Either go back to the main task details tab, or close the popup (as appropriate)
    if (viewLoadedDirectly) {
      onDirectBackClosed();
    } else {
      goBackToBodyDetails();
    }
  };

  useEffect(() => {
    setViewIsReady(false);
    if (!details.userTaskId) return;

    // If there's no close form, we just show a "Are you sure" UI
    if (!matchingTaskType?.closeFormId) {
      setViewIsReady(true);
    } else {
      // Load the form content
      loadFormFromApi(details.userTaskId);
    }
  }, [details.userTaskId]);

  function loadFormFromApi(userTaskId: string) {
    const onFormLoaded = (data: SimpleFormContent | null) => {
      setFormContent(data);
      // If there's an existing close form response, load the answers
      setFormAnswers(details.closeFormInstance?.response.answers || []);
      setViewIsReady(true);
    };

    const onFormLoadError = (error: any) => {
      console.error("Unable to load close form", error);
    };

    tasksApi.getCloseForm(userTaskId, onFormLoaded, onFormLoadError);
  }

  const scrollToTop = () => {
    if (!scrollableContainerRef.current) return;
    scrollableContainerRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFormAnswerChange = (newAnswer: SimpleFormAnswer) => {
    const newAnswers = simpleFormHelper.updateAnswerState(
      newAnswer,
      formAnswers
    );
    setFormIsDirty();
    setFormAnswers(newAnswers);
  };

  const onCloseWithoutForm = () => {
    // No simple form to fill out, just mark this goal as closed
    if (!details.userTaskId) return;

    const onCloseError = (error: any) => {
      setIsSaving(false);
      scrollToTop();
      console.error("Unable to close task", error);
    };

    const onCloseSuccess = (data: ResultStatus) => {
      setIsSaving(false);
      if (data.success) {
        onClosed();
      } else {
        onCloseError(data.message);
      }
    };

    setIsSaving(true);
    tasksApi.closeTaskWithoutForm(
      details.userTaskId,
      usageScenario,
      onCloseSuccess,
      onCloseError
    );
  };

  const onCloseWithForm = () => {
    // There's a form to fill out, so validate then post the response
    if (!details.userTaskId) return;

    const onCloseError = (error: any) => {
      setIsSaving(false);
      scrollToTop();
      console.error("Unable to close task", error);
    };

    const onCloseSuccess = (data: UserTaskSaveResponse) => {
      setIsSaving(false);
      setValidationErrors(null);
      if (data.savedSuccessfully) {
        onClosed();
      } else if (data.validationErrors && data.validationErrors.length > 0) {
        setValidationErrors(data.validationErrors);
      } else {
        onCloseError(data.errorReason);
      }
    };

    // Reset validation errors
    setValidationErrors(null);

    // Post the response
    setIsSaving(true);
    tasksApi.closeTaskWithForm(
      {
        userTaskId: details.userTaskId,
        formId: formContent!.formId,
        answers: formAnswers,
        responseId: details.closeFormInstance?.response.responseId || null,
        questionIdsToPullCommentsFrom:
          matchingTaskType?.commentQuestionIds || [],
      },
      usageScenario,
      onCloseSuccess,
      onCloseError
    );
  };

  // Don't render anything until the view is marked as ready
  if (!matchingTaskType || !viewIsReady) return null;

  const singularTaskTypeName = advancedTaskHelper.ToLowerCase(
    matchingTaskType.singularNameTranslationKeyIdentifier
  );

  let bodyContent: JSX.Element;
  let footerContent: JSX.Element;

  // If there's a Close form, show it, otherwise just do a "Are you sure..." interface
  if (matchingTaskType.closeFormId && formContent) {
    // There is a Close Form that we can display
    // Check if the user is trying to close the popup and if they are show a warning else show the form
    bodyContent =
      userIsTryingToClosePopup ? (
        // Close Form Warning Content
        <DangerAlert
          prefix=""
          message={t("TaskType.Popup.Warning.ChangesMaybeLost")}
        />
      ) : (
        // Close Form Content
        <>
          {taskType && (
            <TaskDetailsSection
              categories={categories}
              taskType={taskType}
              details={details}
              viewType="BODY-FORM-ONLY"
              showComments
            />
          )}
          <SimpleForm
            formContent={formContent}
            answers={formAnswers}
            onChange={handleFormAnswerChange}
            loggedInUser={userContext.user}
            subjectUser={details.ownerEmployee}
            validationErrors={validationErrors}
          />
        </>
      );
    footerContent =
      userIsTryingToClosePopup
        ? (
          // Close Form Warning Footer
          <LeavingWarningFooter
            goBackFromWarning={goBackFromWarning}
            proceedAfterWarning={proceedAfterWarning}
          />
        ) : (
          // Close Form Footer
          <div className="flex flex-row justify-end gap-4">
            <button onClick={onGoBackClick} className="hover:underline">
              {t("TaskType.Popup.Buttons.GoBack")}
            </button>
            <button
              onClick={onCloseWithForm}
              className="btn-primary"
              disabled={isSaving}
            >
              {t("TaskType.Popup.Buttons.SubmitAndClose")}
            </button>
          </div>
        );
  } else {
    // No simple form for closing a task, so show a simple "Are you sure?" interface
    bodyContent = (
      <>
        <p className="my-2">
          {t("TaskType.Popup.CloseView.IntroFormless", {
            taskType: singularTaskTypeName,
          })}
        </p>
        <SummaryTable
          containerClassNames="mb-2"
          rows={[
            {
              name:
                taskType?.titleTranslationKeyIdentifier != null
                  ? t(taskType.titleTranslationKeyIdentifier)
                  : t("TaskType.Popup.Labels.Description"),
              value: details.title,
            },
            {
              name: t("TaskType.Popup.Labels.DueDate"),
              value: (
                <FormattedDate
                  date={details.targetDate}
                  displayMode="DATE-ONLY"
                />
              ),
            },
          ]}
        />
      </>
    );
    footerContent = (
      <div className="flex flex-row justify-end gap-4">
        <button onClick={onGoBackClick} className="hover:underline">
          {t("TaskType.Popup.Buttons.GoBack")}
        </button>

        <button
          onClick={onCloseWithoutForm}
          className="btn-primary"
          disabled={isSaving}
        >
          {t("TaskType.Popup.Buttons.ConfirmCloseFormless", {
            taskType: singularTaskTypeName,
          })}
        </button>
      </div>
    );
  }

  return (
    <ScrollableModalContentTemplate
      footer={footerContent}
      bodyRef={scrollableContainerRef}
    >
      <div className="pt-2">{bodyContent}</div>
    </ScrollableModalContentTemplate>
  );
};

export default AdvancedTaskCloseView;
