import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientTaskTypeCategory } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { UserTaskDetail } from "../../../types/dtos/tasks/advanced-tasks/UserTaskDetail";
import DangerAlert from "../../alerts/DangerAlert";
import { TaskPopupTabLoader } from "./TaskPopupTabLoader";
import {
  SimpleFormAnswer,
  SimpleFormValidationError,
} from "../../../types/dtos/simple-forms";
import AdvancedTaskCloseView from "./details-views/AdvancedTaskCloseView";
import AdvancedTaskReOpenView from "./details-views/AdvancedTaskReOpenView";
import { ClientTaskType } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import AdvancedTaskBodyFormView from "./details-views/AdvancedTaskBodyFormView";
import AdvancedTaskCancelView from "./details-views/AdvancedTaskCancelView";
import { EditUserTaskDto } from "../../../types/dtos/tasks/advanced-tasks/add-popup/EditUserTaskDto";
import { UserTaskSaveResponse } from "../../../types/dtos/tasks/advanced-tasks/api-response/UserTaskSaveResponse";
import taskValidationHelper from "../../../helpers/taskValidationHelper";
import advancedTasksApi from "../../../api/task/advancedTasksApi";
import { useAuth } from "react-oidc-context";
import { AdvancedTaskMainDetailsValidationResult } from "../../../types/tasks/AdvancedTaskMainDetailsValidationResult";
import AdvancedTaskExistingCancelResponseView from "./details-views/AdvancedTaskExistingCancelResponseView";
import AdvancedTaskExistingCloseResponseView from "./details-views/AdvancedTaskExistingCloseResponseView";
import { TaskPopupViewType } from "../../../types/tasks/TaskPopupViewType";
import { advancedTaskHelper, dateHelper } from "../../../helpers";
import AdvancedTaskReviewView from "./details-views/AdvancedTaskReviewView";
import { TaskEditPopupUsageScenario } from "../../../types/tasks/TaskEditPopupUsageScenario";
import { ExistingTaskWarningOrigin } from "../../../types/tasks/ExistingTaskWarningOrigin";
import { InfoBanner } from "../../common";
import cx from "classnames";

interface TaskDetailsPopupTabProps {
  taskType: ClientTaskType | null;
  usageScenario: TaskEditPopupUsageScenario;
  isLoading: boolean;
  isReadOnly: boolean;
  isLocked: boolean;
  categories: ClientTaskTypeCategory[];
  details: UserTaskDetail | null;
  /** The state variable containing the current values for body form answers */
  bodyFormAnswers: SimpleFormAnswer[];
  /* Only applicable when loaded from within a journey/collab-doc **/
  isTaskNewlyCreatedInJourney?: boolean;
  /* What form to display to the user**/
  formToDisplay?: TaskPopupViewType | null;
  /** Whether or not the popup is launched and the user is to be displayed (for example) the close task form */
  isDirectToStatusChangeForm: boolean;
  /** Whether the task details or body form have changed since being loaded */
  bodyFormIsDirty: boolean;
  closeFormIsDirty: boolean;
  cancelFormIsDirty: boolean;
  reviewFormIsDirty: boolean;
  reopenFormIsDirty: boolean;
  userIsTryingToClosePopup: boolean;
  snapshotDate?: Date | null; /* Only applicable when loaded from within a journey/collab-doc **/
  setDetails(details: UserTaskDetail): void;
  onSuccessfulUpdate(): void;
  onSuccessfulCancel(): void;
  onSuccessfulClose(): void;
  onSuccessfulReOpen(): void;
  onBodyViewChange(newModalTitle: string | null, tabsAreHidden: boolean, resetDirtyFormFlags: boolean, userIsTryingToClosePopup: boolean): void;
  onBodyFormAnswerChange(newAnswer: SimpleFormAnswer): void;
  onSuccessfulRemove?(): void;
  setUserIsTryingToClosePopup(close: boolean): void;
  setCloseFormIsDirty(): void;
  setCancelFormIsDirty(): void;
  setReviewFormIsDirty(): void;
  setReopenFormIsDirty(): void;
  warningConfirmationOrigin: ExistingTaskWarningOrigin | null;
  showWarningConfirmation(warningOrigin: ExistingTaskWarningOrigin): void;
  actionLeaveWarningConfirmation(): void;
}

const TaskDetailsPopupTab = ({
  taskType,
  usageScenario,
  isLoading,
  isReadOnly,
  isLocked,
  categories,
  details,
  bodyFormAnswers,
  isTaskNewlyCreatedInJourney,
  formToDisplay,
  isDirectToStatusChangeForm,
  bodyFormIsDirty,
  closeFormIsDirty,
  cancelFormIsDirty,
  reviewFormIsDirty,
  reopenFormIsDirty,
  userIsTryingToClosePopup,
  snapshotDate,
  onBodyViewChange,
  setDetails,
  onSuccessfulUpdate,
  onSuccessfulCancel,
  onSuccessfulClose,
  onSuccessfulReOpen,
  onBodyFormAnswerChange,
  onSuccessfulRemove = undefined,
  setCloseFormIsDirty,
  setCancelFormIsDirty,
  setReviewFormIsDirty,
  setReopenFormIsDirty,
  warningConfirmationOrigin,
  showWarningConfirmation,
  actionLeaveWarningConfirmation
}: TaskDetailsPopupTabProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);
  const taskTypeNameSingularLower = advancedTaskHelper.ToLowerCase(
    taskType?.singularNameTranslationKeyIdentifier ?? ""
  );

  // This is a ref we pass down so we can scroll the popup to the top on demand
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [currentView, setCurrentView] = useState<TaskPopupViewType>("BODY");
  const [taskIsSaving, setTaskIsSaving] = useState<boolean>(false);
  const [showRestrictionError, setShowRestrictionError] =
    useState<boolean>(false);
  const [bodyFormValidationErrors, setBodyFormValidationErrors] = useState<
    SimpleFormValidationError[] | null
  >(null);
  const [
    showErrorUpdatingTaskNotification,
    setShowErrorUpdatingTaskNotification,
  ] = useState<boolean>(false);
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  const [mainDetailsValidationResult, setMainDetailsValidationResult] =
    useState<AdvancedTaskMainDetailsValidationResult>({
      title: null,
      category: null,
      targetDate: null,
    });

  let formattedSnapshotDate = null;
  let formattedLastModifiedDate = null;
  if (snapshotDate && details) {
    formattedSnapshotDate = dateHelper.convertDateFromStringToUtc(
      snapshotDate
    );
    formattedLastModifiedDate = dateHelper.convertDateFromStringToUtc(
      details?.dateLastModified
    );
  }
  // This flag is used to indicate whether the task has been modified since the snapshot date
  // given as the time the collab doc was signed off. Obviously not relevant when accessed
  // from the dashboard or non-completed collab docs.
  const showTaskHasChangedBanner = formattedSnapshotDate != null && formattedLastModifiedDate != null
    && formattedLastModifiedDate > formattedSnapshotDate;

  useEffect(() => {
    if (formToDisplay) {
      // If the user is returning from the warning screen then we want to reset the flags as well
      if (warningConfirmationOrigin == "BACK-BUTTON") {
        handleViewChange(formToDisplay, true);
      } else {
        handleViewChange(formToDisplay);
      }
    }
  }, [formToDisplay]);

  const scrollPopupToTop = () => {
    if (!scrollableContainerRef.current) return;
    scrollableContainerRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleViewChange = (newView: TaskPopupViewType, resetDirtyFormFlags?: boolean, userIsTryingToClosePopup?: boolean) => {
    setCurrentView(newView);

    // Set the modal title
    let newModalTitle: string | null;
    switch (newView) {
      case "CANCEL-TASK-FORM":
      case "CANCEL-TASK-EXISTING-RESPONSE":
        newModalTitle = t("TaskType.Popup.CancelView.Title", {
          taskType: taskTypeNameSingularLower,
        });
        break;
      case "CLOSE-TASK-FORM":
      case "CLOSE-TASK-EXISTING-RESPONSE":
        newModalTitle = t("TaskType.Popup.CloseView.Title", {
          taskType: taskTypeNameSingularLower,
        });
        break;
      case "REVIEW-TASK-FORM":
        newModalTitle = t("TaskType.Popup.ReviewView.Title", {
          taskType: taskTypeNameSingularLower,
        });
        break;
      case "REOPEN-TASK":
        newModalTitle = t("TaskType.Popup.ReOpenView.Title", {
          taskType: taskTypeNameSingularLower,
        });
        break;
      default:
        newModalTitle = null;
        break;
    }

    // Only show the "Details/Comments/History" tabs when we're on the "Body" tab, so not when closing/cancelling/reopening
    const hideBodyViewTabs = newView !== "BODY";

    onBodyViewChange(newModalTitle, hideBodyViewTabs, resetDirtyFormFlags ?? false, userIsTryingToClosePopup ?? false);
  };

  const showBodyForm = () => {
    if (closeFormIsDirty || cancelFormIsDirty || reviewFormIsDirty || reopenFormIsDirty) {
      showWarningConfirmation("BACK-BUTTON");
    } else {
      handleViewChange("BODY", true);
    }
  };

  const goBackFromWarningScreen = () => {
    if (closeFormIsDirty) {
      handleViewChange("CLOSE-TASK-FORM");
    } else if (cancelFormIsDirty) {
      handleViewChange("CANCEL-TASK-FORM");
    } else if (reviewFormIsDirty) {
      handleViewChange("REVIEW-TASK-FORM");
    } else if (reopenFormIsDirty) {
      handleViewChange("REOPEN-TASK");
    }
  }

  const onGoBackFromDirectlyLoadedForm = () => {
    if (closeFormIsDirty || cancelFormIsDirty || reviewFormIsDirty || reopenFormIsDirty) {
      showWarningConfirmation("BACK-BUTTON");
    } else {
      onSuccessfulClose();
    }
  };

  const editExistingCloseResponse = () => {
    handleViewChange("CLOSE-TASK-FORM");
  };

  const editExistingCancelResponse = () => {
    handleViewChange("CANCEL-TASK-FORM");
  };

  /** Checks category, target date etc, not the body form */
  const taskMainDetailsAreValid = (): boolean => {
    const categoryValidity = taskValidationHelper.isCategoryValid(
      details?.categoryId,
      categories
    );
    const targetDateValidity = taskValidationHelper.isTargtDateValid(
      details?.targetDate
    );
    const titleValidity = taskValidationHelper.isTitleValid(details?.title);

    setMainDetailsValidationResult({
      category: categoryValidity,
      targetDate: targetDateValidity,
      title: titleValidity,
    });

    let isValid = true;

    if (
      (categories.length > 0 && !categoryValidity.isValid) ||
      !targetDateValidity.isValid ||
      !titleValidity.isValid
    ) {
      isValid = false;
      setShowValidationErrors(true);
      scrollPopupToTop();
    } else {
      setShowValidationErrors(false);
    }

    return isValid;
  };

  const handleSaveTask = (
    successCallback: (data: UserTaskSaveResponse) => void,
    errorCallback: (error: any) => void
  ) => {
    if (taskMainDetailsAreValid() && details != null) {
      // If the API call succeeds (though beware, it might return validation errors)...
      const onSuccess = (data: UserTaskSaveResponse) => {
        setTaskIsSaving(false);

        if (data.savedSuccessfully) {
          successCallback(data);
        } else {
          if (data.validationErrors && data.validationErrors.length > 0) {
            setBodyFormValidationErrors(data.validationErrors);
          } else if (
            data.errorReason !== null &&
            data.errorReason === "CATEGORY-MAX-LIMIT-REACHED"
          ) {
            setShowRestrictionError(true);
            scrollPopupToTop();
          } else {
            setShowErrorUpdatingTaskNotification(true);
            scrollPopupToTop();
          }
        }
      };

      // If the API call fails...
      const onError = (error: any) => {
        setTaskIsSaving(false);
        setShowErrorUpdatingTaskNotification(true);
        scrollPopupToTop();
        errorCallback(error);
      };

      // Create the dto
      const editDto: EditUserTaskDto = {
        userTaskId: details.userTaskId!,
        taskStatus: details.taskStatus,
        categoryId: details.categoryId,
        title: details.title,
        targetDate: details.targetDate,
        bodyFormResponse: {
          responseId: details.bodyFormInstance.response?.responseId ?? null,
          answers: bodyFormAnswers,
          formId: details.bodyFormInstance.form.formId,
        },
      };

      // Reset some state variables
      setTaskIsSaving(true);
      setBodyFormValidationErrors(null);
      setShowRestrictionError(false);
      setShowErrorUpdatingTaskNotification(false);

      // Call the API
      tasksApi.editTask(editDto, onSuccess, onError);
    }
  };

  const handleCancelTaskButtonClick = () => {
    // If there is an existing cancel form instance then we need to give the user
    // the choice whether to re-submit with these or to edit
    if (details?.cancelFormInstance != null) {
      handleViewChange("CANCEL-TASK-EXISTING-RESPONSE");
    } else {
      // If none was found, show the form views
      handleViewChange("CANCEL-TASK-FORM");
    }
  };

  const handleCloseTaskButtonClick = () => {
    if (isReadOnly) return;

    // If there are changes to the form before the user goes to complete, save them, otherwise
    // just show the close task view
    if (bodyFormIsDirty) {
      handleSaveTask(
        () => {
          checkForExistingCloseResponse();
        },
        () => {
          setShowErrorUpdatingTaskNotification(true);
        }
      );
    } else {
      checkForExistingCloseResponse();
    }
  };

  const checkForExistingCloseResponse = () => {
    // If there is an existing close form instance then we need to give the user
    // the choice whether to re-submit with these or to edit
    if (details?.closeFormInstance != null) {
      handleViewChange("CLOSE-TASK-EXISTING-RESPONSE");
    } else {
      // If none was found, show the form views
      handleViewChange("CLOSE-TASK-FORM");
    }
  };

  const handleReOpenTaskButtonClick = () => {
    if (!isReadOnly) return;
    handleViewChange("REOPEN-TASK");
  };

  const handleRemoveTaskButtonClick = () => {
    if (isReadOnly || details?.userTaskId == null) return;

    tasksApi.deleteTask(
      details.userTaskId,
      () => {
        if (onSuccessfulRemove) {
          onSuccessfulRemove();
        }
      },
      () => { }
    );
  };

  const canReOpenThisTask =
    details != null &&
    details.reviewedInAnswerSetGuidId == null &&
    (details.taskStatus === "COMPLETED" || details.taskStatus === "CANCELLED");

  // Show the button text as "Complete and Review" if there is a close form, otherwise just "Complete"
  const completeButtonText = taskType?.closeFormId
    ? t("TaskType.Popup.Buttons.CompleteAndReview")
    : t("TaskType.Popup.Buttons.Complete");

  return (
    <div className="advanced-task-popup">
      {isLoading && <TaskPopupTabLoader />}
      {!isLoading && details && (
        <div className={cx(
          "flex flex-col gap-3 mb-2",
          snapshotDate != null ? "md:gap-0" : "md:gap-4"
        )}>
          {currentView === "BODY" && (
            <>
              {/* BUTTONS */}
              <div className="flex gap-2 flex-row justify-end pt-2 md:pt-1 empty:hidden">
                {!isTaskNewlyCreatedInJourney && (
                  <>
                    {isReadOnly && canReOpenThisTask && !isLocked && (
                      <>
                        <button
                          className="btn-primary text-xs md:text-sm !px-2"
                          onClick={handleReOpenTaskButtonClick}
                        >
                          {t("TaskType.Popup.Buttons.Reopen")}
                        </button>
                      </>
                    )}
                    {!isReadOnly && details.taskStatus === "ACTIVE" && (
                      <>
                        <button
                          className="btn-primary-alternative px-2"
                          disabled={taskIsSaving}
                          onClick={handleCancelTaskButtonClick}
                        >
                          {t("TaskType.Popup.Buttons.CancelTask").replace(
                            "#TASK_TYPE#",
                            taskTypeNameSingularLower
                          )}
                        </button>
                        <button
                          className="btn-primary !px-2"
                          disabled={taskIsSaving}
                          onClick={handleCloseTaskButtonClick}
                        >
                          {completeButtonText}
                        </button>
                      </>
                    )}
                  </>
                )}
                {isTaskNewlyCreatedInJourney && !isLocked && (
                  <>
                    <button
                      className="btn-primary text-xs md:text-sm !px-2"
                      onClick={handleRemoveTaskButtonClick}
                    >
                      {t("TaskType.Popup.Buttons.RemoveTask").replace(
                        "#TASK_TYPE#",
                        taskTypeNameSingularLower
                      )}
                    </button>
                  </>
                )}
              </div>
            </>
          )}

          {showErrorUpdatingTaskNotification && (
            <DangerAlert
              prefix={t("Common.Validation.Oops")}
              message={t("TaskType.Popup.Validation.ErrorUpdatingTask").replace(
                "#TASK_TYPE#",
                taskTypeNameSingularLower
              )}
            />
          )}

          {showTaskHasChangedBanner && (
            <InfoBanner
              margin=""
              className="mt-1"
              text={t("TaskType.Popup.Info.EditedSinceSubmitted").replace(
                "#TASK_TYPE#",
                taskTypeNameSingularLower
              )}
            />
          )}

          {details && (
            <>
              {currentView === "BODY" && (
                <AdvancedTaskBodyFormView
                  taskType={taskType}
                  bodyFormAnswers={bodyFormAnswers}
                  categories={categories}
                  details={details}
                  isReadOnly={isReadOnly}
                  scrollableContainerRef={scrollableContainerRef}
                  onBodyFormAnswerChange={onBodyFormAnswerChange}
                  onSuccessfulUpdate={onSuccessfulUpdate}
                  setDetails={setDetails}
                  showValidationErrors={showValidationErrors}
                  showRestrictionError={showRestrictionError}
                  bodyFormValidationErrors={bodyFormValidationErrors}
                  taskIsSaving={taskIsSaving}
                  onTaskSave={handleSaveTask}
                  mainDetailsValidationResult={mainDetailsValidationResult}
                  setMainDetailsValidationResult={
                    setMainDetailsValidationResult
                  }
                />
              )}
              {currentView === "CANCEL-TASK-FORM" && (
                <AdvancedTaskCancelView
                  taskType={taskType}
                  details={details}
                  goBackToBodyDetails={showBodyForm}
                  onCancelled={() => {
                    onSuccessfulCancel();
                  }}
                  onDirectBackCancelled={() => {
                    onGoBackFromDirectlyLoadedForm();
                  }}
                  viewLoadedDirectly={
                    usageScenario === "JOURNEY-COLLAB-DOC" &&
                    isDirectToStatusChangeForm
                  }
                  setFormIsDirty={setCancelFormIsDirty}
                  userIsTryingToClosePopup={userIsTryingToClosePopup}
                  goBackFromWarning={goBackFromWarningScreen}
                  proceedAfterWarning={actionLeaveWarningConfirmation}
                />
              )}
              {currentView === "CANCEL-TASK-EXISTING-RESPONSE" &&
                details.cancelFormInstance != null && (
                  <AdvancedTaskExistingCancelResponseView
                    userTaskId={details.userTaskId!}
                    taskTypeId={details.taskTypeId!}
                    cancelFormInstance={details.cancelFormInstance}
                    ownerEmployee={details.ownerEmployee}
                    goBackToBodyDetails={showBodyForm}
                    onEditResponse={editExistingCancelResponse}
                    onCancelled={() => {
                      onSuccessfulCancel();
                    }}
                  />
                )}
              {currentView === "CLOSE-TASK-FORM" && (
                <AdvancedTaskCloseView
                  taskType={taskType}
                  details={details}
                  categories={categories}
                  goBackToBodyDetails={showBodyForm}
                  goBackFromWarning={goBackFromWarningScreen}
                  onClosed={() => {
                    onSuccessfulClose();
                  }}
                  onDirectBackClosed={() => {
                    onGoBackFromDirectlyLoadedForm();
                  }}
                  viewLoadedDirectly={
                    usageScenario === "JOURNEY-COLLAB-DOC" &&
                    isDirectToStatusChangeForm
                  }
                  setFormIsDirty={setCloseFormIsDirty}
                  userIsTryingToClosePopup={userIsTryingToClosePopup}
                  proceedAfterWarning={actionLeaveWarningConfirmation}
                  usageScenario={usageScenario}
                />
              )}
              {currentView === "CLOSE-TASK-EXISTING-RESPONSE" &&
                details.closeFormInstance != null && (
                  <AdvancedTaskExistingCloseResponseView
                    userTaskId={details.userTaskId!}
                    taskTypeId={details.taskTypeId!}
                    closeFormInstance={details.closeFormInstance}
                    ownerEmployee={details.ownerEmployee}
                    usageScenario={usageScenario}
                    goBackToBodyDetails={showBodyForm}
                    onEditResponse={editExistingCloseResponse}
                    onClosed={() => {
                      onSuccessfulClose();
                    }}
                  />
                )}
              {currentView === "REVIEW-TASK-FORM" && (
                <AdvancedTaskReviewView
                  taskType={taskType}
                  details={details}
                  categories={categories}
                  goBackToBodyDetails={showBodyForm}
                  goBackFromWarning={goBackFromWarningScreen}
                  onClosed={() => {
                    onSuccessfulClose();
                  }}
                  onDirectBackClosed={() => {
                    onGoBackFromDirectlyLoadedForm();
                  }}
                  viewLoadedDirectly={
                    usageScenario === "JOURNEY-COLLAB-DOC" &&
                    isDirectToStatusChangeForm
                  }
                  setFormIsDirty={setReviewFormIsDirty}
                  userIsTryingToClosePopup={userIsTryingToClosePopup}
                  proceedAfterWarning={actionLeaveWarningConfirmation}
                />
              )}
              {currentView === "REOPEN-TASK" && (
                <AdvancedTaskReOpenView
                  taskType={taskType}
                  details={details}
                  goBackToBodyDetails={showBodyForm}
                  goBackFromWarning={goBackFromWarningScreen}
                  onReOpened={() => {
                    onSuccessfulReOpen();
                  }}
                  onDirectBackReOpened={() => {
                    onGoBackFromDirectlyLoadedForm();
                  }}
                  viewLoadedDirectly={
                    usageScenario === "JOURNEY-COLLAB-DOC" &&
                    isDirectToStatusChangeForm
                  }
                  setFormIsDirty={setReopenFormIsDirty}
                  userIsTryingToClosePopup={userIsTryingToClosePopup}
                  proceedAfterWarning={actionLeaveWarningConfirmation}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskDetailsPopupTab;
