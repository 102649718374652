import { useRef, useState } from "react";
import { t } from "i18next";
import cx from "classnames";
import UserContext from "../../../state/UserContext";
import React from "react";
import { useAuth } from "react-oidc-context";
import UserTaskComment from "../../../types/dtos/tasks/advanced-tasks/comments/UserTaskCommentDto";
import advancedTasksApi from "../../../api/task/advancedTasksApi";
import ResultStatus from "../../../types/dtos/generic/ResultStatus";
import TaskCommentSection from "./comments/TaskCommentSection";
import { TaskPopupTabLoader } from "./TaskPopupTabLoader";
import UserTaskCommentDto from "../../../types/dtos/tasks/advanced-tasks/comments/UserTaskCommentDto";
import TaskDetailsSection from "./details-views/TaskDetailsSection";
import { ClientTaskType } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { UserTaskDetail } from "../../../types/dtos/tasks/advanced-tasks/UserTaskDetail";
import DangerAlert from "../../alerts/DangerAlert";
import LeavingWarningFooter from "./LeavingWarningFooter";

interface TaskCommentsPopupTabProps {
  userTask: UserTaskDetail | null;
  taskType: ClientTaskType | null;
  isLoading: boolean;
  isReadOnly: boolean;
  isLocked: boolean;
  reviewInAnswerSetGuidId?: string | null;
  comments: UserTaskComment[] | null;
  showTaskDetails?: boolean | undefined;
  setComments(newCommentState: UserTaskComment[], clearCommentFormIsDirtyFlag: boolean): void;
  commentFormIsDirty: boolean;
  setCommentFormIsDirty(): void;
  userIsTryingToClosePopup: boolean;
  goBackFromCommentsWarning(): void;
  actionLeaveWarningConfirmation(): void;
}

const TaskCommentsPopupTab = ({
  taskType,
  isLoading,
  isReadOnly,
  isLocked,
  userTask,
  reviewInAnswerSetGuidId,
  comments,
  showTaskDetails = false,
  setComments,
  setCommentFormIsDirty,
  userIsTryingToClosePopup,
  goBackFromCommentsWarning,
  actionLeaveWarningConfirmation,
}: TaskCommentsPopupTabProps) => {
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);
  const userContext = React.useContext(UserContext);

  const [unsavedNewCommentTextValue, setUnsavedNewCommentTextValue] = useState<string>("");

  // Only prevent the user from adding comments if the task is readonly AND the user is
  // has signed off the task in an answer set, or the task is locked
  const preventCommentsBeingAdded =
    isReadOnly && reviewInAnswerSetGuidId != null || isLocked;

  // Refs
  const commentFormInputRef = useRef<HTMLTextAreaElement>(null);

  const onCommentAdd = (
    comment: string,
    successCallback: () => void,
    errorCallback: () => void
  ) => {
    if (userTask?.userTaskId) {
      tasksApi.addComment(
        userTask.userTaskId,
        comment,
        (data: ResultStatus) => {
          if (data.success) {
            // Add the new comment to the list of comments
            const newComment = data.data as UserTaskCommentDto | undefined;
            if (comments && newComment) {
              setComments([...comments, newComment], true);
            }
          }
          successCallback();
        },
        (error: any) => {
          errorCallback();
        }
      );
    }
  };

  const onCommentEdit = (
    commentId: string,
    comment: string,
    successCallback: () => void,
    errorCallback: () => void
  ) => {
    if (userTask?.userTaskId) {
      tasksApi.editComment(
        userTask.userTaskId,
        commentId,
        comment,
        (data: ResultStatus) => {
          if (data.success) {
            if (comments != null) {
              // Update the comment in the list of comments
              const newComments = comments.map((c) =>
                c.commentId === commentId ? { ...c, comment: comment } : c
              );
              setComments(newComments, false);
            }
          }
          successCallback();
        },
        (error: any) => {
          errorCallback();
        }
      );
    }    
  };

  const onCommentDelete = (commentId: string) => {
    if (userTask?.userTaskId) {
      tasksApi.deleteComment(
        userTask.userTaskId,
        commentId,
        (data: ResultStatus) => {
          // Remove the comment from the list of comments
          const newComments = comments?.filter(
            (comment) => comment.commentId !== commentId
          );
          setComments(newComments ?? [], false);
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  };

  const taskTypeName =
    taskType !== null ? taskType.singularNameTranslationKeyIdentifier : "";

  const content = userIsTryingToClosePopup ? (
    // Warning Content
    <>
      <DangerAlert
        prefix=""
        message={t("TaskType.Popup.Warning.ChangesMaybeLost")}
      />
      <LeavingWarningFooter
        goBackFromWarning={goBackFromCommentsWarning}
        proceedAfterWarning={actionLeaveWarningConfirmation}
      />
    </>
  ) : (
    // Content
    <>
      {userTask?.userTaskId && taskType && (
        <>
          {showTaskDetails && (
            <>
              <TaskDetailsSection
                categories={taskType.categories ?? []}
                taskType={taskType}
                details={userTask}
                viewType="BODY-AND-CLOSE-FORM"
              />
              <h3 className="text-lg font-semibold mt-2">
                {t("Common.Comments")}
              </h3>
            </>
          )}

          <TaskCommentSection
            taskTypeName={taskTypeName}
            clientColor={"#" + userContext.user.client.primaryHexColour}
            isReadOnly={preventCommentsBeingAdded}
            comments={comments}
            onCommentAdded={onCommentAdd}
            onCommentEdited={onCommentEdit}
            onCommentDeleted={onCommentDelete}
            commentFormInputRef={commentFormInputRef}
            setCommentFormIsDirty={setCommentFormIsDirty}
            unsavedNewCommentTextValue={unsavedNewCommentTextValue}
            setUnsavedNewCommentTextValue={setUnsavedNewCommentTextValue}
          />
        </>
      )}
    </>
  );

  return (
    <div className={cx(
      userIsTryingToClosePopup ? "" : "min-h-60"
    )}>
      {isLoading && <TaskPopupTabLoader />}
      {!isLoading && (
        <div className="pt-2">{content}</div>
      )}
    </div>
  );
};

export default TaskCommentsPopupTab;
